<template>
  <div
    v-if="bannerData.visible && config.public.site.allowPurchase"
    class="bg-blue rounded-lg w-full flex flex-col md:flex-row gap-x-4 gap-y-2 items-center py-2 px-6 text-white font-sans font-medium text-sm"
  >
    <i18n-t keypath="trialSignup.trialExpiresIn" tag="span" scope="global">
      {{ bannerData.expiryDays }}
    </i18n-t>
    <NuxtLink :to="{ name: 'subscriptionCheckout' }">
      <CommonButton
        bg="bg-white"
        text="text-blue"
        text-hover="hover:text-blue"
        bg-hover="hover:bg-gray-100"
        >{{ $t('trialSignup.addPayment') }}</CommonButton
      >
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
const userStore = useUserStore()
const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

const { data, refresh } = await useAsyncData(async () => {
  if (
    !userStore.isLoggedIn ||
    !userStore.user?.clientId ||
    !userStore.user?.isAdmin
  ) {
    return []
  }

  return await nuxtApp.$api.subscription.list(userStore.user.clientId)
})

const bannerData = computed(() =>
  getSubscriptionBannerData(data.value ?? [], userStore.user ?? undefined)
)
onMounted(() => {
  refresh()
})
</script>
